import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { FabricFeatureService } from 'src/app/@Core/service/fabric-feature.service';

@Component({
  selector: 'app-fabric-feature',
  templateUrl: './fabric-feature.component.html',
  styleUrls: ['./fabric-feature.component.scss'],
})
export class FabricFeatureComponent implements OnInit {
  displayDialog: boolean = false;
  selectedFabric: any = {
    id: null,
    value: {
      gb: '',
      de: '',
      fr: '',
      es: '',
      it: '',
      pt: '',
      gr: '',
      ch: '',
      ua: '',
      il: '',
      sa: ''
    },
  };

  areFieldsDisabled = true;
  displayDeleteDialog = false;

  fabricFeatures: any[] = [];

  languages: string[] = ['gb', 'de', 'fr', 'es', 'it', 'pt', 'gr', 'ru', 'ch', 'ua', 'il', 'sa'];

  constructor(
    private router: Router,
    private fabricService: FabricFeatureService
  ) {}

  ngOnInit(): void {
    this.fabricService.getAllFabrics().subscribe(
      (res) => {
        this.fabricFeatures = res;
      },
      (err) => {}
    );
  }

  // close modal dialog
  hideDialog() {
    //clear attirbutes
    this.selectedFabric = {
      id: null,
      value: {
        gb: '',
        de: '',
        fr: '',
        es: '',
        it: '',
        pt: '',
        gr: '',
        ch: '',
        ua: '',
        il: '',
        sa: ''
      },
    };

    this.displayDialog = false;
    this.areFieldsDisabled = true;
    this.displayDeleteDialog = false;
  }

  // open modal dialog
  openModalDialog(fabric: any, modalType: string) {
    // set selected row's data

    if (modalType == 'edit') this.areFieldsDisabled = false;
    this.selectedFabric = fabric;

    this.displayDialog = true;
  }

  editFabric() {
    this.fabricService.saveFabric(this.selectedFabric).subscribe(
      () => {
        this.fabricService.getAllFabrics().subscribe(
          (res) => {
            this.fabricFeatures = res;
            this.hideDialog();
          },
          (err) => {
            this.hideDialog();
          }
        );
      },
      (err) => {
        this.hideDialog();
      }
    );
  }

  openCreateDialog() {
    this.areFieldsDisabled = false;
    this.displayDialog = true;
  }

  openDeleteDialog(fabric: any) {
    this.displayDeleteDialog = true;
    this.selectedFabric = fabric;
  }

  deleteProduct() {
    this.fabricService.deleteFabricById(this.selectedFabric?.id).subscribe(
      () => {
        this.fabricService.getAllFabrics().subscribe(
          (res) => {
            this.fabricFeatures = res;
          },
          (err) => {}
        );
        this.hideDialog();
      },
      (err) => {}
    );
    this.hideDialog();
  }

  customSort(event: SortEvent) {
    event?.data?.sort((data1, data2) => {
      let value1 = data1[event.field!];
      let value2 = data2[event?.field!];
      let result = 0;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order! * result;
    });
  }
}
