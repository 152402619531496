import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { UserService } from 'src/app/@Core/service/user.service';
import { WashingInstructionService } from 'src/app/@Core/service/washing-instruction.service';
import { WashingInstruction } from '../../dto/WashingInstruction';

/*import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;*/

import * as html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver';
import { SvgGeneratorService } from 'src/app/@Core/service/svg-generator.service';
import { FileService } from 'src/app/@Core/service/file.service';

@Component({
  selector: 'app-list-washing-instructions',
  templateUrl: './list-washing-instructions.component.html',
  styleUrls: ['./list-washing-instructions.component.scss', '../../../../../assets/order/style/list-washing-instructions.component.css'],
})
export class ListWashingInstructionsComponent implements OnInit {

  @ViewChild('pdf') pdf!: ElementRef;

  @ViewChild('fabricDiv')
  fabricDiv!: ElementRef;

  @ViewChild('instructionDiv')
  instructionDiv!: ElementRef;

  displayDialog: boolean = false;
  displayShowDialog: boolean = false;
  displayAddNoteDialog: boolean = false;
  displayUpdateStatusDialog: boolean = false;
  displayConfirmationDialog: boolean = false;
  loading: boolean = false;
  noteText: string = '';
  fabricLanguages = [
    'GB', 'DE', 'FR', 'ES', 'IT', 'PT', 'GR', 'UA', 'IL', 'SA'
  ]

  instructionLanguages = [
    'GB', 'DE', 'FR', 'ES', 'IT', 'PT', 'GR'
  ]

  originLanguages = ['gb', 'fr', 'pt', 'gr', 'ua', 'il', 'sa']
  originValue = '';
  russianType:any = {
    name: '',
    value: ''
  };

  selectedInstruction: WashingInstruction = {
    id: '',
    fabricColor: '',
    warningLabelList: [],
    originCountry: {
      id: '',
      countryName: '',
      value: {
        gb: '',
        de: '',
        fr: '',
        es: '',
        it: '',
        pt: '',
        gr: '',
        ru: '',
        ch: '',
        ua: '',
        il: '',
        sa: ''
      },
    },
    poNumber: '',
    washingSymbols: [],
    chineseSizes: [],
    productPartModels: [],
    russianProductPartModels: [],
    chineseProductPartModels: [],
    russianLabelModel: {
      russianProductType: {
        name: '',
        value: ''
      },
      russianModelDate: '',
      isActive: false,
      quantity: 0

    },
    washingInstructions: [],
    creator: {
      company: '',
      firstName: '',
      lastName: ''
    },
    status: '',
    criticsModels: [],
    createDate: undefined,
    quantity: 0,
    productCategory: '',
    productGroupType: ''
  }

  listInstructions: WashingInstruction[] = [];
  userRole: string = '';

  selectedScale = 2.0
  fabricTextSize = 6 * this.selectedScale;
  instructionTextSize = 5.5 * this.selectedScale;

  totalChinese = 0;

  groupTypeAddress = {
    'Men + Unisex + Boys + Girls': 'United Legwear & Apparel Europe B.V.<br>Jacobus Spijkerdreef 20<br>2132 PZ Hoofddorp<br>The Netherlands',
    'Women': 'Mamiye Europe B.V.<br>Jacobus Spijkerdreef 20<br>2132 PZ Hoofddorp<br>The Netherlands',
    'Default': 'Scotch & Soda B.V.,<br>Jacobus Spijkerdreef 20,<br>2132 PZ, Hoofddorp,<br>the Netherlands'
  }

  website: string = 'scotchandsoda.com'

  constructor(
    private instructionService: WashingInstructionService,
    private snackBar: MatSnackBar,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private userService: UserService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private svgGenerator: SvgGeneratorService
  ) { }

  ngOnInit(): void {

    // this.instructionService.getAllInstructionsList().subscribe(res=>{
    //   this.listInstructions=res;
    // },err=>{});
    if(!localStorage.getItem('token')){
      this.router.navigateByUrl('/login')
    }

    this.userService.getCurrent().subscribe((res: { userRole: string; }) => {
      this.userRole = res.userRole;
    })

    this.instructionService.getAllByStatus('NOT_APPROVED').subscribe((res: any) => {

      this.listInstructions = res
      console.log(res)

    })

    this.changeFabricTextSize()
    this.changeInstructionTextSize()
  }

  hideDeleteDialog(){
   this.displayConfirmationDialog = false 
  }

  // hide  note dialog
  hideNoteDialog() {
    this.displayAddNoteDialog = false;
  }
  // open  note dialog
  openNoteDialog(instruction: any) {
    this.selectedInstruction = instruction
    this.displayAddNoteDialog = true;
  }

  sendNote() {
    return this.instructionService.addCritic(this.selectedInstruction.id, this.noteText).subscribe(
      () => {
        this.noteText = '';
        this.hideNoteDialog();
        this.instructionService.getAllByStatus('NOT_APPROVED').subscribe((res: any) => {

          this.listInstructions = res
          console.log(res)

        })
      },
      (err) => {
        this.openSnackBar('Critic could not send, please try later', 'OK');
      }
    );
  }


  // hide delete instruction dialog
  hideShowDialog() {
    this.displayShowDialog = false;
  }

  // open delete instruction dialog
  openShowDialog(instruction: any) {

    this.fabricLanguages = [
      'GB', 'DE', 'FR', 'ES', 'IT', 'PT', 'GR', 'UA', 'IL', 'SA'
    ]
  
    this.instructionLanguages = [
      'GB', 'DE', 'FR', 'ES', 'IT', 'PT', 'GR'
    ]
  
    this.originLanguages = ['gb', 'fr', 'pt', 'gr', 'ua', 'il', 'sa']

    this.selectedInstruction = instruction;
    if(!this.selectedInstruction.chineseProductPartModels){
      this.selectedInstruction.chineseProductPartModels = []
    }
    if (!(instruction.originCountry.value['sa'])) {
      if (this.originLanguages.includes('sa')) {
        this.originLanguages.pop()
        this.fabricLanguages.pop()
      }

      //this.website = 'scotch-soda.com'
    }
    if (!(instruction.originCountry.value['il'])) {
      if (this.originLanguages.includes('il')) {
        this.originLanguages.pop()
        this.fabricLanguages.pop()
      }

      //this.website = 'scotch-soda.com'
    }
    if (!(instruction.originCountry.value['ua'])) {
      if (this.originLanguages.includes('ua')) {
        this.originLanguages.pop()
        this.fabricLanguages.pop()
      }

      //this.website = 'scotch-soda.com'
    }

    if ((instruction.originCountry.value['ua'])) {
      if (!this.originLanguages.includes('ua'))
      this.originLanguages.push('ua')

      if (!this.fabricLanguages.includes('UA'))
        this.fabricLanguages.push('UA')

      //this.website = 'scotch-soda.com'
    }
    if ((instruction.originCountry.value['il'])) {
      if (!this.originLanguages.includes('il'))
      this.originLanguages.push('il')

      if (!this.fabricLanguages.includes('IL'))
        this.fabricLanguages.push('IL')

      //this.website = 'scotch-soda.com'
    }



    

    
    /*if(this.selectedInstruction.productGroupType && this.selectedInstruction.productGroupType !== 'Default'){
      if(!this.originLanguages.includes('ru'))
        this.originLanguages.push('ru')
    }else{
      let ruIndex = this.originLanguages.findIndex(item => item === 'ru')
      if(ruIndex > 0)
        this.originLanguages.splice(ruIndex, 1)
    }*/
    this.displayShowDialog = true;
    this.originValue = ''
    this.originLanguages.forEach(lang => {
      this.originValue = this.originValue + ' / ' + instruction.originCountry.value[lang];
    })
    this.originValue = this.originValue.slice(2, this.originValue.length)
    this.totalChinese = 0;
    for(let size of instruction.chineseSizes){
      this.totalChinese = this.totalChinese + parseInt(size.quantity);
    }
    this.changeFabricTextSize()
    this.changeInstructionTextSize()
  }

  openDeleteDialog(instruction:any){
    if(!instruction.chineseProductPartModels){
      instruction.chineseProductPartModels = []
    }
    this.selectedInstruction = instruction;
    console.log(instruction)
    this.displayConfirmationDialog = true;
    
  }

  deleteInstruction(){

    return this.instructionService.deleteById(this.selectedInstruction?.id).subscribe(()=>{

      this.instructionService.getAllByStatus('NOT_APPROVED').subscribe((res: any) => {

        this.displayConfirmationDialog=false;
        this.listInstructions = res

      })
    },err=>{
      this.openSnackBar('Instruction could not be deleted!','OK')
    })
  }

  openModalDialog(instruction: any) {
    this.displayDialog = true;
    this.selectedInstruction = instruction;
    this.router.navigateByUrl('/create?no='+instruction.id)
  }

  openCreate(){
    this.router.navigateByUrl('/create')
  }


  updateStatusDialog(list: any) {
    this.confirmationService.confirm({
      message: 'Approve Order with PO Number: ' + list.poNumber,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.instructionService.approve(list.id).subscribe(() => {

          this.instructionService.getAllByStatus('NOT_APPROVED').subscribe((res: any) => {

            this.listInstructions = res
            console.log(res)

          })

        });
        this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Note sended succesfully' });

      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
            break;
        }
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  changeFabricTextSize(){
    this.changeDetectorRef.detectChanges();
    var height = this.fabricDiv.nativeElement.offsetHeight;
    console.log('Height: ' + height);

    if (height > 500) {
      this.fabricTextSize = this.fabricTextSize - 1
    } else if (height < 300 && this.fabricTextSize / this.selectedScale < 6) {
      this.fabricTextSize = this.fabricTextSize + 1
    }
    else{
      return;
    }
    this.changeFabricTextSize()
    
  }

  changeInstructionTextSize(){
    this.changeDetectorRef.detectChanges();
    var height = this.instructionDiv.nativeElement.offsetHeight;
    console.log('Height: ' + height);

    if (height > 500) {
      this.instructionTextSize = this.instructionTextSize - 1
    } else if (height < 300 && this.instructionTextSize / this.selectedScale < 5.5) {
      this.instructionTextSize = this.instructionTextSize + 1
    }
    else{
      return;
    }
    this.changeInstructionTextSize()
    
  }

  print() {

    /*const pdfTable = this.pdf.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).download(); */



    //window.open(this.pdf.nativeElement.toDataURL())

    /*var c = document.querySelector('#pdf')
    html2canvas(this.pdf.nativeElement).then(canvas => {

      canvas.toBlob(blob => {
        // To download directly on browser default 'downloads' location
        let link = document.createElement("a");
        link.download = "image.png";
        link.href = URL.createObjectURL(blob!);
        link.click();

        // To save manually somewhere in file explorer
        saveAs(blob, 'image.png');

    },'image/png');


    })*/

    const options = {
      filename: 'export.pdf',
      image: { type: 'png' },
      html2canvas: { scale: 1 },
      jsPDF: { format: 'a2', orientation: 'landscape' }
    }

    const content = this.pdf.nativeElement;

    /*html2pdf()
    .from(content)
    .set(options)
    .print()*/

    const WindowPrt = window.open('', '', '');
    WindowPrt!.document.write('<html><head><title></title>');
    WindowPrt!.document.write('<link rel="stylesheet" href="styles.css">')
    WindowPrt!.document.write('<link rel="stylesheet" href="assets/order/style/list-washing-instructions.component.css">')
    //WindowPrt!.document.write('<style>' + style!.innerHTML + '</style>')
    WindowPrt!.document.write('</head><body >');
    WindowPrt!.document.write('<div id="printdiv">');
    WindowPrt!.document.write(content.innerHTML);
    WindowPrt!.document.write('</div>');
    WindowPrt!.document.write('</body></html>');
    WindowPrt!.document.close();
    WindowPrt!.focus();
    setTimeout(function(){WindowPrt!.print();WindowPrt!.close();  },1000);



    /*const iframe = this.iframe.nativeElement;

    this.portalHost = new DomPortalOutlet(
      iframe.contentDocument.body,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );

    const portal = new TemplatePortal(
      this.pdf,
      this.viewContainerRef,
      {
        selectedInstruction:this.selectedInstruction
      }
    );

    this.portalHost.attach(portal);

    iframe.contentWindow.print()*/


  }

  pdfExport(){
    const options = {
      filename: 'export.pdf',
      image: { type: 'png' },
      html2canvas: { scale: 1 },
      jsPDF: { format: 'a2', orientation: 'landscape' }
    }

    const content = this.pdf.nativeElement;

    html2pdf()
    .from(content)
    .set(options)
    .save()
  }

  transformText(text: string){

    let lines = text.split('\n')
    let newText = '';
    lines.forEach(item => {

      newText = newText + item + '<br>';

    })

    return newText;

  }

  /*svgExport(){
    this.svgGenerator.generate(this.selectedInstruction, this.fabricTextSize / this.selectedScale, this.instructionTextSize / this.selectedScale)

  }*/

}

