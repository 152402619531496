import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { ProductService } from 'src/app/@Core/service/product.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  displayDialog: boolean = false;
  selectedProduct: any = {
    id: null,
    type: 'WASHING',
    value: {
      gb: '',
      de: '',
      fr: '',
      es: '',
      it: '',
      pt: '',
      gr: '',
      ch: '',
      ua: '',
      il: '',
      sa: ''
    },
  };

  areFieldsDisabled = true;
  displayDeleteDialog = false;

  products: any[] = [];

  languages: string[] = ['gb', 'de', 'fr', 'es', 'it', 'pt', 'gr', 'ru', 'ch', 'ua', 'il', 'sa'];

  constructor(private router: Router, private productService: ProductService) {}

  ngOnInit(): void {
    this.productService.getAllProducts().subscribe(
      (res) => {
        this.products = res;
      },
      (err) => {}
    );
  }

  // close modal dialog
  hideDialog() {
    //clear attirbutes
    this.selectedProduct = {
      id: null,
      type: 'WASHING',
      value: {
        gb: '',
        de: '',
        fr: '',
        es: '',
        it: '',
        pt: '',
        gr: '',
        ch: '',
        ua: '',
        il: '',
        sa: ''
      },
    };

    this.displayDialog = false;
    this.areFieldsDisabled = true;
    this.displayDeleteDialog = false;
  }

  // open modal dialog
  openModalDialog(product: any, modalType: string) {
    // set selected row's data

    if (modalType == 'edit') this.areFieldsDisabled = false;
    this.selectedProduct = product;

    this.displayDialog = true;
  }

  editProduct() {
    this.productService.saveProduct(this.selectedProduct).subscribe(
      () => {
        this.productService.getAllProducts().subscribe(
          (res) => {
            this.products = res;
            this.hideDialog();
          },
          (err) => {
            this.hideDialog();
          }
        );
      },
      (err) => {
        this.hideDialog();
      }
    );
  }

  openCreateDialog() {
    this.areFieldsDisabled = false;
    this.displayDialog = true;
  }

  openDeleteDialog(product: any) {
    this.displayDeleteDialog = true;
    this.selectedProduct = product;
  }

  deleteProduct() {
    this.productService.deleteProductById(this.selectedProduct?.id).subscribe(
      () => {
        this.productService.getAllProducts().subscribe(
          (res) => {
            this.products = res;
          },
          (err) => {}
        );
        this.hideDialog();
      },
      (err) => {}
    );
    this.hideDialog();
  }

  //  // show other products table
  //  navigateOtherProducts(product: any) {
  //   this.selectedWarning = [warning.otherlangs];
  //   this.router.navigate(['warnings/other'], {
  //     state: {
  //       warnings:
  //         this.selectedWarning !== undefined ? this.selectedWarning[0] : null,
  //     },
  //   });
  // }

  customSort(event: SortEvent) {
    event?.data?.sort((data1, data2) => {
      let value1 = data1[event.field!];
      let value2 = data2[event?.field!];
      let result = 0;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order! * result;
    });
  }
}
