import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { InstructionsServiceService } from 'src/app/@Core/service/instructions-service.service';
import { WashingInstructionService } from 'src/app/@Core/service/washing-instruction.service';
import { WashingInstruction } from '../../dto/WashingInstruction';

import * as html2pdf from 'html2pdf.js';
import { SvgGeneratorService } from 'src/app/@Core/service/svg-generator.service';

@Component({
  selector: 'app-completed-washing-instruction',
  templateUrl: './completed-washing-instruction.component.html',
  styleUrls: ['./completed-washing-instruction.component.scss', '../../../../../assets/order/style/list-washing-instructions.component.css'],
})
export class CompletedWashingInstructionComponent implements OnInit {

  displayDialog: boolean = false;
  displayDeleteDialog: boolean = false;
  displayUpdateStatusDialog: boolean = false;
  displayInstructionDialog:boolean=false;
  loading: boolean = false;
  noteText: string = '';

  fabricLanguages = [
    'GB', 'DE', 'FR', 'ES', 'IT', 'PT', 'GR', 'UA', 'IL', 'SA'
  ]

  instructionLanguages = [
    'GB', 'DE', 'FR', 'ES', 'IT', 'PT', 'GR'
  ]

  originLanguages = ['gb', 'fr', 'pt', 'gr', 'ua', 'il', 'sa']
  originValue = '';
  russianType = '';

  selectedInstruction: WashingInstruction = {
    id: '',
    fabricColor: '',
    warningLabelList: [],
    originCountry: {
      id: '',
      countryName: '',
      value: {
        gb: '',
        de: '',
        fr: '',
        es: '',
        it: '',
        pt: '',
        gr: '',
        ru: '',
        ch: '',
        ua: '',
        il: '',
        sa: ''
      },
    },
    poNumber: '',
    washingSymbols: [],
    chineseSizes: [],
    productPartModels: [],
    russianProductPartModels: [],
    chineseProductPartModels: [],
    russianLabelModel: {
      russianProductType: {
        name: '',
        value: ''
      },
      russianModelDate: '',
      isActive: false,
      quantity: 0

    },
    washingInstructions: [],
    creator: {
      company: '',
      firstName: '',
      lastName: ''
    },
    status: '',
    criticsModels: [],
    createDate: undefined,
    quantity: 0,
    productCategory: '',
    productGroupType: ''

  }

  completedInstructions: WashingInstruction[] = [];

  selectedScale = 2.0
  fabricTextSize = 6 * this.selectedScale;
  instructionTextSize = 5.5 * this.selectedScale;

  totalChinese = 0;

  @ViewChild('pdf') pdf!: ElementRef;

  @ViewChild('fabricDiv')
  fabricDiv!: ElementRef;

  @ViewChild('instructionDiv')
  instructionDiv!: ElementRef;

  groupTypeAddress = {
    'Men + Unisex + Boys + Girls': 'United Legwear & Apparel Europe B.V.<br>Jacobus Spijkerdreef 20<br>2132 PZ Hoofddorp<br>The Netherlands',
    'Women': 'Mamiye Europe B.V.<br>Jacobus Spijkerdreef 20<br>2132 PZ Hoofddorp<br>The Netherlands',
    'Default': 'Scotch & Soda B.V.,<br>Jacobus Spijkerdreef 20,<br>2132 PZ, Hoofddorp,<br>the Netherlands'
  }

  website: string = 'scotchandsoda.com'

  constructor(
    private instructionService: WashingInstructionService,
    private snackBar: MatSnackBar,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private svgGenerator: SvgGeneratorService
  ) {}

  ngOnInit(): void {

    this.instructionService.getAllByStatus('APPROVED').subscribe((res: any) => {

      this.completedInstructions = res
      console.log(res)

    })




  }






  // hide delete instruction dialog
  hideDeleteDialog(){
    this.displayDeleteDialog=false;
  }

  // // open delete instruction dialog
  // openDeleteDialog(instruction:any){
  //   this.selectedInstruction=instruction;
  //   this.displayDeleteDialog=true;
  // }

  // deleteInstruction(instruction:any){

  //   return this.instructionService.deleteInstructionById(instruction?.id).subscribe(()=>{
  //      this.displayDeleteDialog=false;
  //   },err=>{
  //     this.openSnackBar('instruction can not deleted!','OK')
  //   })
  // }

  // openModalDialog(instruction:any){
  //   this.displayDialog=true;
  //   this.selectedInstruction=instruction;
  // }
  openModalDialog(){
    this.router.navigateByUrl('/create')
  }



  updateStatusDialog(list: any) {
    this.confirmationService.confirm({
      message: 'Approve Order with PO Number: '+list.poNumber,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          this.messageService.add({severity:'info', summary:'Confirmed', detail:'Note sended succesfully'});
          this.instructionService.complete(list.id).subscribe(() => {

            this.instructionService.getAllByStatus('APPROVED').subscribe((res: any) => {
  
              this.completedInstructions = res
              console.log(res)
  
            })
  
          });
      },
      reject: (type:any) => {
          switch(type) {
              case ConfirmEventType.REJECT:
                  this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
              break;
              case ConfirmEventType.CANCEL:
                  this.messageService.add({severity:'warn', summary:'Cancelled', detail:'You have cancelled'});
              break;
          }
      }
  });
  }

  changeFabricTextSize(){
    this.changeDetectorRef.detectChanges();
    var height = this.fabricDiv.nativeElement.offsetHeight;
    console.log('Height: ' + height);

    if (height > 500) {
      this.fabricTextSize = this.fabricTextSize - 1
    } else if (height < 300 && this.fabricTextSize / this.selectedScale < 6) {
      this.fabricTextSize = this.fabricTextSize + 1
    }
    else{
      return;
    }
    this.changeFabricTextSize()
    
  }

  changeInstructionTextSize(){
    this.changeDetectorRef.detectChanges();
    var height = this.instructionDiv.nativeElement.offsetHeight;
    console.log('Height: ' + height);

    if (height > 500) {
      this.instructionTextSize = this.instructionTextSize - 1
    } else if (height < 300 && this.instructionTextSize / this.selectedScale < 5.5) {
      this.instructionTextSize = this.instructionTextSize + 1
    }
    else{
      return;
    }
    this.changeInstructionTextSize()
    
  }

  openInstructionDialog(instruction:any){

    this.fabricLanguages = [
      'GB', 'DE', 'FR', 'ES', 'IT', 'PT', 'GR', 'UA', 'IL', 'SA'
    ]
  
    this.instructionLanguages = [
      'GB', 'DE', 'FR', 'ES', 'IT', 'PT', 'GR'
    ]
  
    this.originLanguages = ['gb', 'fr', 'pt', 'gr', 'ua', 'il', 'sa']

    this.selectedInstruction=instruction;
    if(!this.selectedInstruction.chineseProductPartModels){
      this.selectedInstruction.chineseProductPartModels = []
    }

    if (!(instruction.originCountry.value['sa'])) {
      if (this.originLanguages.includes('sa')) {
        this.originLanguages.pop()
        this.fabricLanguages.pop()
      }

      //this.website = 'scotch-soda.com'
    }
    if (!(instruction.originCountry.value['il'])) {
      if (this.originLanguages.includes('il')) {
        this.originLanguages.pop()
        this.fabricLanguages.pop()
      }

      //this.website = 'scotch-soda.com'
    }
    if (!(instruction.originCountry.value['ua'])) {
      if (this.originLanguages.includes('ua')) {
        this.originLanguages.pop()
        this.fabricLanguages.pop()
      }

      //this.website = 'scotch-soda.com'
    }

    if ((instruction.originCountry.value['ua'])) {
      if (!this.originLanguages.includes('ua'))
      this.originLanguages.push('ua')

      if (!this.fabricLanguages.includes('UA'))
        this.fabricLanguages.push('UA')

      //this.website = 'scotch-soda.com'
    }
    if ((instruction.originCountry.value['il'])) {
      if (!this.originLanguages.includes('il'))
      this.originLanguages.push('il')

      if (!this.fabricLanguages.includes('IL'))
        this.fabricLanguages.push('IL')

      //this.website = 'scotch-soda.com'
    }
    /*if(this.selectedInstruction.productGroupType && this.selectedInstruction.productGroupType !== 'Default'){
      if(!this.originLanguages.includes('ru'))
        this.originLanguages.push('ru')
    }else{
      let ruIndex = this.originLanguages.findIndex(item => item === 'ru')
      if(ruIndex > 0)
        this.originLanguages.splice(ruIndex, 1)
    }*/
   
   
   this.displayInstructionDialog=true;
   this.originValue = ''
   this.originLanguages.forEach(lang => {
    this.originValue = this.originValue + ' / ' + instruction.originCountry.value[lang];
  })
  this.originValue = this.originValue.slice(2, this.originValue.length)
  this.totalChinese = 0;
  for(let size of instruction.chineseSizes){
    this.totalChinese = this.totalChinese + parseInt(size.quantity);
  }
  this.changeFabricTextSize()
    this.changeInstructionTextSize()
  }

  hideInstructionDialog(){

    this.selectedInstruction={
      id: '',
      fabricColor: '',
      warningLabelList: [],
      originCountry: {
        id: '',
        countryName: '',
        value: {
          gb: '',
          de: '',
          fr: '',
          es: '',
          it: '',
          pt: '',
          gr: '',
          ru: '',
          ch: '',
          ua: '',
          il: '',
          sa: ''
        },
      },
      poNumber: '',
      washingSymbols: [],
      chineseSizes: [],
      productPartModels: [],
      russianProductPartModels: [],
      chineseProductPartModels: [],
      russianLabelModel: {
        russianProductType: {
          name: '',
          value: ''
        },
        russianModelDate: '',
        isActive: false,
        quantity: 0
  
      },
      washingInstructions: [],
      creator: {
        company: '',
        firstName: '',
        lastName: ''
      },
      status: '',
      criticsModels: [],
      createDate: undefined,
      quantity: 0,
      productCategory: '',
      productGroupType: ''

    }

    this.displayInstructionDialog=false;
  }


  openReloadAlert(item:any){
    this.confirmationService.confirm({
      message: 'Etiket geri yüklensin mi',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          // do process for reload clicked item
          this.instructionService.disapprove(item?.id).subscribe(()=>{

            this.instructionService.getAllByStatus('APPROVED').subscribe((res:any)=>{
              this.completedInstructions=res;

              console.log(res);
            },err=>{
              this.openSnackBar('Instruction can not Loaded!','OK')
            })

            this.messageService.add({severity:'info', summary:'Confirmed', detail:'Etiket basıldı.'});
          },err=>{
            console.log(err)
          })
         // this.messageService.add({severity:'info', summary:'Confirmed', detail:'Etiket başarı ile geri yüklendi.'});
      },
      reject: (type:any) => {
          switch(type) {
              case ConfirmEventType.REJECT:
                  this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
              break;
              case ConfirmEventType.CANCEL:
                  this.messageService.add({severity:'warn', summary:'Cancelled', detail:'You have cancelled'});
              break;
          }
      }
  });
  }

  openLabelPrintedAlert(item:any){
    this.confirmationService.confirm({
      message: 'Etiket basıldı mı?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          // do process for  clicked item

          this.instructionService.complete(item?.id).subscribe(()=>{

            this.instructionService.getAllByStatus('APPROVED').subscribe((res:any)=>{
              this.completedInstructions=res;

              console.log(res);
            },err=>{
              this.openSnackBar('Instruction can not Loaded!','OK')
            })

            this.messageService.add({severity:'info', summary:'Confirmed', detail:'Etiket basıldı.'});
          },err=>{
            this.openSnackBar('Instruction can not printed!','OK')
          })

      },
      reject: (type:any) => {
          switch(type) {
              case ConfirmEventType.REJECT:
                  this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
              break;
              case ConfirmEventType.CANCEL:
                  this.messageService.add({severity:'warn', summary:'Cancelled', detail:'You have cancelled'});
              break;
          }
      }
  });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  print() {

    /*const pdfTable = this.pdf.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).download(); */



    //window.open(this.pdf.nativeElement.toDataURL())

    /*var c = document.querySelector('#pdf')
    html2canvas(this.pdf.nativeElement).then(canvas => {

      canvas.toBlob(blob => {
        // To download directly on browser default 'downloads' location
        let link = document.createElement("a");
        link.download = "image.png";
        link.href = URL.createObjectURL(blob!);
        link.click();

        // To save manually somewhere in file explorer
        saveAs(blob, 'image.png');

    },'image/png');


    })*/

    const options = {
      filename: 'export.pdf',
      image: { type: 'png' },
      html2canvas: { scale: 1 },
      jsPDF: { format: 'a2', orientation: 'landscape' }
    }

    const content = this.pdf.nativeElement;

    /*html2pdf()
    .from(content)
    .set(options)
    .print()*/

    const WindowPrt = window.open('', '', '');
    WindowPrt!.document.write('<html><head><title></title>');
    WindowPrt!.document.write('<link rel="stylesheet" href="../styles.css">')
    WindowPrt!.document.write('<link rel="stylesheet" href="../assets/order/style/list-washing-instructions.component.css">')
    //WindowPrt!.document.write('<style>' + style!.innerHTML + '</style>')
    WindowPrt!.document.write('</head><body >');
    WindowPrt!.document.write('<div id="printdiv">');
    WindowPrt!.document.write(content.innerHTML);
    WindowPrt!.document.write('</div>');
    WindowPrt!.document.write('</body></html>');
    WindowPrt!.document.close();
    WindowPrt!.focus();
    setTimeout(function(){WindowPrt!.print();WindowPrt!.close();  },1000);



    /*const iframe = this.iframe.nativeElement;

    this.portalHost = new DomPortalOutlet(
      iframe.contentDocument.body,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );

    const portal = new TemplatePortal(
      this.pdf,
      this.viewContainerRef,
      {
        selectedInstruction:this.selectedInstruction
      }
    );

    this.portalHost.attach(portal);

    iframe.contentWindow.print()*/


  }

  pdfExport(){
    const options = {
      filename: 'export.pdf',
      image: { type: 'png' },
      html2canvas: { scale: 1 },
      jsPDF: { format: 'a2', orientation: 'landscape' }
    }

    const content = this.pdf.nativeElement;

    html2pdf()
    .from(content)
    .set(options)
    .save()
  }

  transformText(text: string){

    let lines = text.split('\n')
    let newText = '';
    lines.forEach(item => {

      newText = newText + item + '<br>';

    })

    return newText;

  }

  svgExport(){
    this.svgGenerator.generate(this.selectedInstruction, this.fabricTextSize / this.selectedScale, this.instructionTextSize / this.selectedScale)

  }

}
